import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Wrapper = styled.section`
height: 100%;
  position: relative;
  overflow-y: hidden;


  background: #000000;
`
const BgImg = styled(Img)`
  position: fixed;
  height: calc(100vh - 4em);
  overflow-y: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  background: #000000;
  width: 100%;
`

const Scroller = styled.div`

overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background: #000000;
  scroll-snap-type: x mandatory;	

  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;

`

const Card = styled.div`

display: inline-block;

height: 100%;
width: 100%;
margin-left: 5%;
margin-right: 30%;
background: #000000;

@media (max-width: 800px) {
  height: 50%;
  width: 200%;
}

`
const VerticalCard = styled.div`

display: inline-block;

height: 100%;
width: 38%;
margin-left: 18%;
margin-right: 30%;
background: #000000;

@media (max-width: 800px) {
  height: 100%;
  width: 100%;
  margin-left: 0%;
}

`


const MyImage = props => (
 
<Scroller>

  {props.image.map((image, index) => {
    if (image.node.hero.fluid.aspectRatio>1) {
      return <Card>    <BgImg fluid={image.node.hero.fluid}/>  </Card>;
    } else {
      return <VerticalCard>    <BgImg fluid={image.node.hero.fluid}/>  </VerticalCard>;
    }
      })}
  </Scroller>
)

export default MyImage
