import React from 'react'
import { graphql } from 'gatsby'
import LayoutForPortfolio from '../components/LayoutForPortfolio'
import MyImage from '../components/MyImage'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostLinks from '../components/PostLinks'
import PostDetails from '../components/PostDetails'
import SEO from '../components/SEO'

const PortfolioTemplate = ({ data, pageContext }) => {

  return (
    <LayoutForPortfolio>
     <MyImage image={data.allContentfulPortfolio.edges} height={'90vh'} />
    </LayoutForPortfolio>
  )
}

export const query = graphql`
  query {
    allContentfulPortfolio(sort: { fields: [hero___title], order: ASC }) {
      edges {
        node {
      hero {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
}
}
`

export default PortfolioTemplate
