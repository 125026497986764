import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { globalStyles } from '../styles/globalStyles.js'

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};
  height: 100vh;
  overflow-y: hidden;
  background: #abc;

	margin: 0;
	padding: 0;
`


const LayoutForPortfolio = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <Root className="siteRoot">
      <div  className="siteContent">
       
        <Menu />
        <div id="main">{props.children}</div>
      </div>

      <Global styles={globalStyles} />
    </Root>
  )
}

export default LayoutForPortfolio
